import React from 'react';
import { useNavigate } from 'react-router-dom';

const AppCard = ({ items = [] }) => {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <div className='row m-0'>
      {items.map((ele) => (
        <div
          className='col col-md-4 col-sm-6 col-12 g-4'
          key={ele.id}
          onClick={() => handleClick(ele.link)}
        >
          <div className='d-flex flex-column gap-2 align-items-center bg-white h-100 p-5 app__card-body pointer'>
            <div>
              <img src={ele.image} alt={'No Image'} height={60} width={60} />
            </div>
            <span className='text-primary fw-bold fs-5'>{ele.text}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppCard;
