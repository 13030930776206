import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { colorVariant } from '../../constants/appConstants';

const AppModal = ({
  show,
  title,
  titleClassName,
  children,
  animation,
  acceptText = 'Ok',
  cancelText = 'Cancel',
  handleCancel,
  handleAccept,
  cancelBtnVariant = colorVariant.danger,
  acceptBtnVariant = colorVariant.primary,
  showFooter = true,
  showHeader = true,
  hideAcceptButton = false,
  size = 'md',
  hideCancelButton = false,
  disableAcceptButton = false,
  ...rest
}) => {
  return (
    <Modal show={show} onHide={handleCancel} animation={animation} size={size} {...rest}>
      {showHeader && (
        <Modal.Header closeButton>
          <Modal.Title className={titleClassName}>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {showFooter && (
        <Modal.Footer>
          {!hideCancelButton && (
            <Button variant={cancelBtnVariant} onClick={handleCancel}>
              {cancelText}
            </Button>
          )}
          {!hideAcceptButton && (
            <Button
              variant={acceptBtnVariant}
              onClick={handleAccept}
              disabled={disableAcceptButton}
            >
              {acceptText}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AppModal;
