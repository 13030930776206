import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectItem {
  value: string | number;
  label: string;
}

interface DropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  title: string;
  value: string | number;
  selectItems: SelectItem[];
  handleChange: (value: string | number) => void;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  disabledTitle?: boolean;
  loading?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  title,
  value,
  selectItems = [],
  handleChange,
  disabled = false,
  error = false,
  errorMessage = '',
  disabledTitle = false,
  loading = false,
  ...rest
}) => {
  const tCommon = useTranslation().t;

  return (
    <Fragment>
      <div className='dropdown-container' style={{ position: 'relative' }}>
        <select
          className={`form-select rounded-2 ${error ? 'border-danger' : ''} select-dropdown`}
          disabled={disabled}
          onChange={(e) => handleChange(e.target.value)}
          value={value}
          {...rest}
        >
          {loading ? (
            <option value={''} disabled>
              {tCommon('loading')}...
            </option>
          ) : (
            <>
              <option value='' disabled={disabledTitle}>
                {title}
              </option>
              {selectItems.map((ele) => (
                <option key={ele.value} value={ele.value}>
                  {ele.label}
                </option>
              ))}
            </>
          )}
        </select>
      </div>
      {error && <div className='text-danger'>{errorMessage}</div>}
    </Fragment>
  );
};

export default Dropdown;
